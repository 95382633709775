.bg-Profile {
    background-color: #f6f1e9;
    background-image: url("../../../public/ProfileBG.png");
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    color: #fff;
  }

  .whatsapp-btn-container {
    position: fixed;
    right: 30px;
    opacity: 0;
    bottom: -50px;
    padding: 24px;
    padding-bottom: 15px; 
    animation: fade-up1 1000ms forwards;
    animation-delay: 1000ms;
}

@keyframes fade-up1 {
    100% {
        bottom: 15px;
        opacity: 1;
    }
}

.whatsapp-btn-container .whatsapp-btn {
    font-size: 48px;
    color: #25D366;
    display: inline-block;
    transition: all 400ms;
}

.whatsapp-btn-container .whatsapp-btn:hover {
    transform: scale(1.2);
}

.updateDataUser {
    color: green;
    font-size: 18px;
}

.periodeTxt {
    float: right;
    text-transform: uppercase;
    margin-left: 6px;
    background-color: #ff8400;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

.keteranganUpdate {
    color: green;
    font-size: 16px;
    margin-left: -240px;
}

.whatsapp-btn-container span {
    position: absolute;
    top: 0;
    left: 4px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #075e54;
    transform: rotateZ(20deg) translateX(10px);
    opacity: 0;
    transition: all 400ms;
}

.whatsapp-btn-container .whatsapp-btn:hover + span {
    transform: rotateZ(0deg) translateX(0px);
    opacity: 1;
}

.btn-contactus {
    width: 45px;
}

  .containerProfile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    display: flex;
    margin-top: -20px;
}

.wrapper-1 {
    width: 800px;
    height: 50px;
    background: white;
    border-radius: 15px;
    text-align: center;
    padding-top: 10px;
}

.wrapper-1 h1 {
    color: #ff8400;
    display: flex;
    justify-content: center;
}

.garis {
    width: 720px;
    height: 1px;
    background: #ff8400;
    margin-left: 40px;
    margin-top: 5px;
}

.wrapper-2 {
    width: 800px;
    height: 535px;
    background: white;
    border-radius: 15px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 100px;
}

.wrapper-2 h1 {
    text-align: center;
    padding-top: 20px;
    color: black;
    font-size: 30px;
}

.formProfile {
    width: 800px;
    height: 380px;
    background: white;
    text-align: center;
    border-radius: 5px;
}

.formProfile .flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 15px;
}

.inputBox {
    width: 35%;
    margin-left: 60px;
    margin-top: 20px;
}

.grid-1 {
    display: grid;
    grid-template-columns: auto auto;
}

.grid-2 {
    display: grid;
    grid-template-columns: auto auto;
}
.grid-3 {
    display: grid;
    grid-template-columns: auto auto;
}

.inputBox .span-nama {
    text-align: left;
    display: block;
    margin-top: 4px;
    margin-left: 22px;
    font-size: 20px;
    color: black;
}

.inputBox .span-unggah {
    text-align: left;
    display: block;
    margin-top: 15px;
    margin-left: 22px;
    font-size: 20px;
    color: black;
}

.txt-unggahImg {
    font-size: 10px;
}

.inputBox .span-nim {
    text-align: left;
    display: block;
    margin-top: 15px;
    margin-left: 22px;
    font-size: 20px;
    color: black;
}

.inputBox .span-prodi {
    text-align: left;
    display: block;
    margin-top: 15px;
    margin-left: 22px;
    font-size: 20px;
    color: black;
}

.inputBox .span-angkatan {
    text-align: left;
    display: block;
    margin-top: 15px;
    margin-left: 22px;
    font-size: 20px;
    color: black;
}

.inputBox .span-tgllahir {
    text-align: left;
    display: block;
    margin-top: 15px;
    margin-left: 22px;
    font-size: 20px;
    color: black;
}

.inputBox .box-nama {
    width: 680px;
    border-radius: 15px;
    border: none;
    background-color: #dce1ee;
    padding: 12px 14px;
    font-size: 17px;
    color: black;
    margin-top: 10px;
}

.inputBox .box-unggah {
    width: 330px;
    border-radius: 15px;
    border: none;
    background-color: #dce1ee;
    padding: 12px 14px;
    font-size: 17px;
    color: black;
    margin-top: 10px;
}

.inputBox .box-nim {
    width: 330px;
    border-radius: 15px;
    border: none;
    background-color: #dce1ee;
    padding: 12px 14px;
    font-size: 17px;
    color: black;
    margin-top: 10px;
}

.inputBox .box-prodi {
    width: 330px;
    border-radius: 15px;
    border: none;
    background-color: #dce1ee;
    padding: 12px 14px;
    font-size: 17px;
    color: black;
    margin-top: 10px;
}

.inputBox .box-angkatan {
    width: 330px;
    border-radius: 15px;
    border: none;
    background-color: #dce1ee;
    padding: 12px 14px;
    font-size: 17px;
    color: black;
    margin-top: 10px;
}

.inputBox .box-tgllahir {
    width: 330px;
    border-radius: 15px;
    border: none;
    background-color: #dce1ee;
    padding: 12px 14px;
    font-size: 17px;
    color: black;
    margin-top: 10px;
}

.container-prodi {
    margin-left: 20px;
}

.container-tgllahir {
    margin-left: 20px;
}

.container-btn {
    margin-top: 110px;
}

.btn-back {
    width: 280px;
    border-radius: 15px;
    border: none;
    background-color: #ff3e4a;
    padding: 12px 14px;
    font-size: 17px;
    color: white;
    margin-top: 10px;
}

.btn-daftar {
    width: 480px;
    border-radius: 15px;
    border: none;
    background-color: #39ca2c;
    padding: 12px 14px;
    font-size: 17px;
    color: white;
    margin-top: 10px;
    margin-left: 20px;
}

.btn-back a {
  color: #fff;
  text-decoration: none;
}

.btn-daftar a {
  color: #fff;
  text-decoration: none;
}

.btn-back a:hover {
    color: #ff3e4a;
}

.btn-daftar a:hover {
    color: #39ca2c;
}
  
.swal2-modal {
    direction: rtl;
}

  @media screen and (max-width: 768px) {
    .margin {
      height: 0vh;
    }
    .bg-Profile {
      background-position: left;
      height: 100vh;
    }

    .whatsapp-btn-container {
        position: fixed;
        right: 30px;
        opacity: 0;
        bottom: -50px;
        padding: 24px;
        padding-bottom: 0px; 
        margin-right: -33px;
        animation: fade-up1 1000ms forwards;
        animation-delay: 1000ms;
    }
    
    @keyframes fade-up1 {
        100% {
            bottom: 0px;
            opacity: 1;
        }
    }

    .wrapper-2 {
      width: 380px;
      height: 510px;
      background: white;
      border-radius: 15px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 100px;
  }
  .wrapper-1 {
      width: 320px;
      height: 50px;
      background: white;
      border-radius: 15px;
      text-align: center;
      padding-top: 10px;
      font-size: 12px;
  }
  .formProfile {
      width: 380px;
      height: 330px;
      background: white;
      text-align: center;
      border-radius: 5px;
  }
  .garis {
      width: 280px;
      height: 1px;
      background: #ff8400;
      margin-left: 20px;
      margin-top: 2.5px;
  }
  .inputBox {
      width: 35%;
      margin-left: 10px;
      margin-top: 8px;
  }
  .inputBox .span-nama {
      text-align: left;
      display: block;
      margin-top: 6px;
      margin-left: 18px;
      font-size: 16px;
      color: black;
  }
  
  .inputBox .span-unggah {
    text-align: left;
    display: block;
    margin-top: 15px;
    margin-left: 18px;
    font-size: 16px;
    color: black;
  }

  .txt-unggahImg {
      font-size: 10px;
  }
  .inputBox .span-nim {
      text-align: left;
      display: block;
      margin-top: 15px;
      margin-left: 18px;
      font-size: 16px;
      color: black;
  }
  
  .inputBox .span-prodi {
      text-align: left;
      display: block;
      margin-top: 15px;
      margin-left: 18px;
      font-size: 16px;
      color: black;
  }
  
  .inputBox .span-angkatan {
      text-align: left;
      display: block;
      margin-top: 15px;
      margin-left: 18px;
      font-size: 16px;
      color: black;
  }
  
  .inputBox .span-tgllahir {
      text-align: left;
      display: block;
      margin-top: 15px;
      margin-left: 18px;
      font-size: 16px;
      color: black;
  }
  
  .inputBox .box-nama {
      width: 360px;
      border-radius: 15px;
      border: none;
      background-color: #dce1ee;
      padding: 14px 13px;
      font-size: 14px;
      color: black;
      margin-top: 10px;
  }
  
  .inputBox .box-unggah {
    width: 175px;
    border-radius: 15px;
    border: none;
    background-color: #dce1ee;
    padding: 14px 13px;
    font-size: 14px;
    color: black;
    margin-top: 10px;
  }
  .inputBox .box-nim {
      width: 175px;
      border-radius: 15px;
      border: none;
      background-color: #dce1ee;
      padding: 14px 13px;
      font-size: 14px;
      color: black;
      margin-top: 10px;
  }
  
  .inputBox .box-prodi {
      width: 175px;
      border-radius: 15px;
      border: none;
      background-color: #dce1ee;
      padding: 14px 13px;
      font-size: 14px;
      color: black;
      margin-top: 10px;
  }
  
  .inputBox .box-angkatan {
      width: 175px;
      border-radius: 15px;
      border: none;
      background-color: #dce1ee;
      padding: 14px 13px;
      font-size: 14px;
      color: black;
      margin-top: 10px;
  }
  
  .inputBox .box-tgllahir {
      width: 175px;
      border-radius: 15px;
      border: none;
      background-color: #dce1ee;
      padding: 14px 13px;
      font-size: 14px;
      color: black;
      margin-top: 10px;
  }
  .grid-1 {
      display: grid;
      grid-template-columns: auto auto;
  }
  
  .grid-2 {
      display: grid;
      grid-template-columns: auto auto;
  }
  .grid-3 {
      display: grid;
      grid-template-columns: auto auto;
  }
  .container-prodi {
      margin-left: 10px;
  }
  
  .container-tgllahir {
      margin-left: 10px;
  }
  
  .container-btn {
      margin-top: 130px;
  }
  .btn-back {
      width: 180px;
      border-radius: 15px;
      border: none;
      background-color: #ff3e4a;
      padding: 12px 14px;
      font-size: 17px;
      color: white;
      margin-top: 10px;
  }
  
  .btn-daftar {
      width: 180px;
      border-radius: 15px;
      border: none;
      background-color: #39ca2c;
      padding: 12px 14px;
      font-size: 17px;
      color: white;
      margin-top: 10px;
      margin-left: 15px;
  }
  .keteranganUpdate {
    color: green;
    font-size: 12px;
    margin-left: -178px;
    position: absolute;
    margin-top: -10px;
}
  }