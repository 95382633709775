.bg-InProgres {
    background-color: #f6f1e9;
    background-image: url("../../../public/UNIKAMA-BG.jpg");
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 23%;
    color: #fff;
  }
  
  .row-InProgres {
    justify-content: center;
    text-align: center;
  }

  .row-InProgres p {
    font-size: 45px;
    color: white;
  }
 
  .row-InProgres h1 {
    font-size: 75px;
    color: white;
  }

  @media screen and (max-width: 768px) {
    .margin-InProgres {
      height: 40vh;
    }
    .bg-InProgres {
      background-position: left;
      padding-top: 80%;
      height: 100vh;
    }
    .bg-InProgres .row-InProgres {
      width: 100%;
    }
    .row-InProgres p {
        font-size: 25px;
        color: white;
      }
     
      .row-InProgres h1 {
        font-size: 35px;
        color: white;
      }
  }