.nav-db {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    background: white;
    width: 55px;
    overflow: hidden;
    transition: width 0.2s linear;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}

.judul-db {
    list-style: none;
    display: flex;
    transition: all 0.5s ease;
    margin-top: 15px;
    margin-top: 30px;
}

.logoDashboard {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  margin-top: -15px;
}

.judul-db .span-db {
    font-size: 20px;
    color: #ff8400;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: -2px;
}

.item-db {
    margin-top: 20px;
}

.link-db {
    position: relative;
    color: rgb(85, 83, 83);
    font-size: 14px;
    display: table;
    width: 300px;
    padding: 10px;
    text-decoration: none;
   }
   
.itemDashboard {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.span-db {
 position: relative;
 margin-left: 12px;
}

.txt-db {
    position: relative;
    margin-left: 20px;
    font-size: 18px;
}

.li-db {
    list-style: none;
}

.link-db:hover{
    background: #eee;
}

.nav-db:hover {
    background-image: url("../../../public/bar-BG.jpg");
    width: 250px;
    transition: all 0.5s ease;
}

.buttonLogout {
    background-color: rgb(221, 24, 24);
    color: white;
    padding: 15px 20px;
    margin: 8px 0;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    width: 160px;
    font-weight: bold;
  }

