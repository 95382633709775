
.datatable {
    background-color: white;
    margin-left:  120px;
    margin-top: 15px;
    width: 1100px;
    height: 100%;
}

.judulDB2 {
    margin-left: 120px;
    margin-top: 20px;
    font-size: 22px;
}

.cellAction {
    display: flex;
    align-items: center;
    gap: 15px;
}

.approveButton {
    padding: 2px 5px;
    border-radius: 12px;
    width: 90px;
    text-align: center;
    justify-content: center;
    color: aliceblue;
    background-color: green;
    border: 1px solid green;
    cursor: pointer;
}

.rejectButton {
    padding: 2px 5px;
    border-radius: 12px;
    width: 90px;
    text-align: center;
    justify-content: center;
    color: aliceblue;
    background-color: red;
    border: 1px solid red;
    cursor: pointer;
}

.openButton {
    padding: 2px 5px;
    border-radius: 12px;
    width: 90px;
    text-align: center;
    justify-content: center;
    color: aliceblue;
    background-color: rgb(5, 153, 252);
    border: 1px solid rgb(5, 153, 252);
    cursor: pointer;
    font-size: 14px;
    height: 26.02px;
}

.dropdown-db1 {
    width: 160px;
    position: absolute;
    margin-left: 878px;
    margin-top: -30px;
    font-size: 14px;
    height: 30px;
    border-radius: 6px;
}

.btnDD-db1 {
    width: 120px;
    position: absolute;
    margin-left: 1050px;
    margin-top: -30px;
    font-size: 14px;
    height: 30px;
    border-radius: 6px;
    color: aliceblue;
    background-color: #39a7ff;
    border: 1px solid #39a7ff;
    cursor: pointer;
}

.txtImg-2 {
    height: 30px;
    width: 120px;
    display: absolute;
    justify-content: center;
    align-items: center;
}