.containerContact{
    display: flex;
    flex-direction: row;
}

.bgContact {
    height: 500px;
}

.Contact {
    height: 100vh;
    background-color: #f1f1f1;
    width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.Contact2 {
    height: 100vh;
    background-color: #ffffff;
    width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.box-contact {
    width: 330px;
    border-radius: 15px;
    border: none;
    background-color: #f1f1f1;
    padding: 12px 14px;
    font-size: 17px;
    color: black;
    margin-top: 10px;
}

.container-contact {
    margin-top: 60px;
    width: 330px;
}

.box-grup {
    width: 330px;
    border-radius: 15px;
    border: none;
    background-color: #f1f1f1;
    padding: 12px 14px;
    font-size: 17px;
    color: black;
    margin-top: 10px;
}

.container-grup {
    margin-top: 40px;
    width: 330px;
}

.btn-add {
    margin-top: 50px;
    width: 180px;
    border-radius: 15px;
    border: none;
    background-color: #39a7ff;
    padding: 12px 14px;
    font-size: 17px;
    color: white;
}

.linkBtn {
    width: fit-content;
    border-radius: 5px;
    border: none;
    background-color: #000000;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
}

.h1-Contact {
    font-size: 42px;
}

.span-cobaLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 330px;
    margin-top: 12px;
}

.btn-add2 {
    margin-top: 12px;
    border: none;
    font-size: 17px;
    color: #39a7ff;
    background-color: #ffffff;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    text-decoration: none;
}

.btn-add3 {
    margin-top: 70px;
    display: flex;
    border: none;
    font-size: 19px;
    color: #39a7ff;
    background-color: none;
    text-align: center;
    justify-content: center;
    align-items: center;
}