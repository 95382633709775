
.datatableClassGroupingDB {
    background-color: white;
    margin-left:  120px;
    margin-top: 15px;
    width: 1100px;
    height: 100%;
}

.judulDB3 {
    margin-left: 120px;
    margin-top: 20px;
    font-size: 22px;
}

.cellActionDB3 {
    display: flex;
    align-items: center;
    gap: 15px;
}

.approveButtonDB3 {
    padding: 2px 5px;
    border-radius: 12px;
    width: 90px;
    text-align: center;
    justify-content: center;
    color: aliceblue;
    background-color: green;
    border: 1px solid green;
    cursor: pointer;
}

.rejectButtonDB3 {
    padding: 2px 5px;
    border-radius: 12px;
    width: 90px;
    text-align: center;
    justify-content: center;
    color: aliceblue;
    background-color: red;
    border: 1px solid red;
    cursor: pointer;
}

.classSelect {
    width: 280px;
    height: 35px;
    border-radius: 15px;
    text-align: center;
}

.addClassInput {
    width: 400px;
  }
  
  .removeClassButton {
    padding: 5 }

.LoadingScreen {
    font-size: 60px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    display: flex;
    height: 100vh;
    width: 100%;
}

.loader {
    margin-left: 20px;
    margin-top: 45px;
    float: right;
    width: 60px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1s steps(4) infinite;
  }
  @keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}

.textIdInput {
    margin-left: 10px;
    margin-top: 1px;
    width: fit-content;
    font-size: 14px;
}

.txtId {
    float: right;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: white;
    background-color: rgb(7, 118, 221);
    padding-left: 15px;
    border-radius: 15px;
    padding-right: 15px;
    margin-left: 10px;
}