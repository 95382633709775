.bg-InVerif {
    background-color: #f6f1e9;
    background-image: url("../../../public/UNIKAMA-BG.jpg");
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 18%;
    color: #fff;
  }
  
  .row-InVerif {
    justify-content: center;
    text-align: center;
  }

  .row-InVerif p {
    font-size: 50px;
    color: white;
  }
 
  .row-InVerif h1 {
    font-size: 75px;
    color: white;
  }

  .btn-join {
    width: 480px;
    border-radius: 15px;
    border: none;
    background-color: #39ca2c;
    padding: 12px 14px;
    font-size: 17px;
    color: white;
    margin-top: 10px;
}

  .btn-join a {
    color: #fff;
    text-decoration: none;
  }

  .btn-join a:hover {
    color: #39ca2c;
}

.container-btnjoin {
    margin-top: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
}

  @media screen and (max-width: 768px) {
    .margin-InVerif {
      height: 40vh;
    }
    .bg-InVerif {
      background-position: left;
      padding-top: 80%;
      height: 100vh;
    }
    .bg-InVerif .row-InVerif {
      width: 100%;
    }
    .row-InVerif p {
        font-size: 25px;
        color: white;
      }
     
      .row-InVerif h1 {
        font-size: 30px;
        color: white;
      }
      .btn-join {
        width: 180px;
        border-radius: 15px;
        border: none;
        background-color: #39ca2c;
        padding: 12px 14px;
        font-size: 17px;
        color: white;
        margin-top: 10px;
    }
    .container-btnjoin {
        margin-top: 5px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .btn-join a:hover {
        color: white;
    }
  }