
.datatableClassGroupingDB3 {
    background-color: white;
    margin-left:  120px;
    margin-top: 15px;
    width: 1100px;
    height: 100%;
}

.judulDB3 {
    margin-left: 120px;
    margin-top: 20px;
    font-size: 22px;
}

.cellActionDB3 {
    display: flex;
    align-items: center;
    gap: 15px;
}

.approveButtonDB3 {
    padding: 2px 5px;
    border-radius: 12px;
    width: 90px;
    text-align: center;
    justify-content: center;
    color: aliceblue;
    background-color: green;
    border: 1px solid green;
    cursor: pointer;
}

.exportButton3 {
    padding: 5px 5px;
    border-radius: 12px;
    width: 90px;
    text-align: center;
    justify-content: center;
    color: #1c680d;
    background-color: #ffffff;
    border: 1px solid #1c680d;
    cursor: pointer;
}

.detailsButton3 {
    padding: 5px 5px;
    border-radius: 12px;
    width: 90px;
    text-align: center;
    justify-content: center;
    color: #050C9C;
    background-color: #ffffff;
    border: 1px solid #050C9C;
    cursor: pointer;
}


.removeButton3 {
    padding: 5px 5px;
    border-radius: 12px;
    width: 90px;
    text-align: center;
    justify-content: center;
    color: aliceblue;
    background-color: rgb(221, 24, 24);
    border: 1px solid rgb(221, 24, 24);
    cursor: pointer;
}

.enterclassdb3 {
   width: 300px;
   position: absolute;
   margin-left: 678px;
   margin-top: -43px;
   font-size: 22px;
}
.enterclassdb3 input {
    border-radius: 6px;
    height: 38px;
}

.addclass3 {
    padding: 12px 5px;
    border-radius: 6px;
    width: 100px;
    height: 39px;
    text-align: center;
    justify-content: center;
    color: aliceblue;
    background-color: #39a7ff;
    border: 1px solid #39a7ff;
    cursor: pointer;
    position: absolute;
    margin-left: 778px;
    margin-top: -7px;
}

.linkdetails-db3 {
    text-decoration: none;
}
