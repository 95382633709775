.bg-404 {
    background-color: #f6f1e9;
    background-image: url("../../../public/BG-404.png");
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 18%;
    padding-right: 60px;
    color: #fff;
  }
  
  .row-404 {
    margin-left: 30px;
    justify-content: center;
    text-align: center;
  }

  .row-404 p {
    font-size: 25px;
    color: #0174be;
  }
 
  .row-404 h1 {
    font-size: 160px;
    color: #0174be;
  }

  @media screen and (max-width: 768px) {
    .margin-404 {
      height: 40vh;
    }
    .bg-404 {
      background-position: center;
      padding-top: 35%;
      height: 100vh;
    }
    .bg-404 .row-404 {
      width: 100%;
    }
  }