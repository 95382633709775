.bg-Home {
    background-color: #f6f1e9;
    background-image: url("../../../public/HomeBG.png");
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 18%;
    padding-right: 60px;
    color: #fff;
  }
  
  .row {
    margin-left: 30px;
  }

  .row p {
    font-size: 21px;
    color: #0174be;
  }
 
  .row h1 {
    font-size: 140px;
    color: #0174be;
  }

  @media screen and (max-width: 768px) {
    .marginhome {
      height: 0vh;
    }
    .bg-Home {
      background-position: left;
      padding-top: 50%;
      height: 100vh;
    }
    .bg-Home .row {
      width: 100%;
    }
  }
  