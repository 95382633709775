.bg-userLogin {
  background-color: #f6f1e9;
  background-image: url("../../../public/LoginBG.png");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  color: #fff;
}
.containerUserLog {
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

.formuser {
  background-color: white;
  width: 420px;
  height: 454px;
  margin-top: 23px;
  border-radius: 14px;
  box-shadow: 5px 8px 11px #999797;
}

.chaptcha {
  margin-top: 5px;
}

.containerUserLog h1 {
  color: #ff8400;
  padding-top: 25px;
}

.container-User {
  color: rgb(0, 0, 0);
  position: absolute;
  left: 49%;
  top: 54%;
  transform: translate(-50%,-50%);
  width: 300px;
  text-align: left;
  margin-left: 18px;
  margin-top: 15px;
}

input[type=number] {
  width: 101%;
  padding: 10px;
  margin: 9px 0;
  border-radius: 3px;
  border: 1px solid #000000;
  box-sizing: border-box;
  font-size: 16px;
}

.box-tgllahir {
  width: 101%;
  padding: 10px;
  margin: 9px 0;
  border-radius: 3px;
  border: 1px solid #000000;
  box-sizing: border-box;
  font-size: 16px;
}

.containerUserLog{
  justify-content: center;
  align-items: center;
}

.garisuser {
  width: 337px;
  height: 2px;
  background: #ff8400;
  margin-left: 40px;
  margin-top: 11px;
}

.buttonLogin {
  background-color: rgb(14, 85, 216);
  color: white;
  padding: 15px 20px;
  margin: 8px 0;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  width: 101%;
  font-weight: bold;
}

button:hover {
  opacity: 0.8;
}

.remember {
  padding: 7px;
  color: black;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .margin {
    height: 0vh;
  }
  .bg-userLogin {
    background-position: left;
    height: 100vh;
  }
  .garisuser {
    width: 280px;
    height: 1px;
    background: #ff8400;
    margin-left: 45px;
    margin-top: 2.5px;
}
.formuser {
  background-color: white;
  width: 375px;
  height: 470px;
  margin-top: 3px;
  border-radius: 15px;
}
.container-User {
  margin-left: 2px;
  margin-top: -7px;
}
}
