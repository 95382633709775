.headerLog {
  background-color: #ffffff;
  opacity: 80%;
  margin: 0 0px;
  width: 100%;
}

header .nav {
  display: flex !important;
}

.logo1Log {
  position: absolute;
  margin-left: 25px;
  margin-top: 1.5px;
  width: 70px;
  height: 70px;
}

.logo2Log {
  position: absolute;
  margin-left: 98px;
  margin-top: 12px;
  width: 99.75px;
  height: 51px;
}

.startLog {
    background-color: #ff8400;
    padding: 35px 200px;
    color: #fff;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
  } 

@media screen and (max-width: 768px) {
  .startLog {
    clip-path: none;
  }
  .headerLog {
    margin: 0;
    background-color: #ff8400;
    position: relative;
  }
  .logo1Log {
    display: none;
  }
  
  .logo2Log {
    display: none;
  }

  .mobile-navLog {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #ff8400;
  }
}