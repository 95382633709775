.bg-InProgres {
    background-color: #f6f1e9;
    background-image: url("../../../public/UNIKAMA-BG.jpg");
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 23%;
    color: #fff;
  }
  
  .row-InProgres {
    justify-content: center;
    text-align: center;
  }

  .row-InProgres p {
    font-size: 45px;
    color: white;
  }
 
  .row-InProgres h1 {
    font-size: 75px;
    color: white;
  }

  .btn-download {
    width: 480px;
    border-radius: 15px;
    border: none;
    background-color: #39a7ff;
    padding: 12px 14px;
    font-size: 17px;
    color: white;
    margin-top: 10px;
}

  .btn-download a {
    color: #fff;
    text-decoration: none;
  }

  .btn-download a:hover {
    color: #39a7ff;
}

.container-btnjoin {
    margin-top: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
}

  @media screen and (max-width: 768px) {
    .margin-InProgres {
      height: 40vh;
    }
    .bg-InProgres {
      background-position: left;
      padding-top: 80%;
      height: 100vh;
    }
    .bg-InProgres .row-InProgres {
      width: 100%;
    }
    .row-InProgres p {
        font-size: 25px;
        color: white;
      }
     
      .row-InProgres h1 {
        font-size: 35px;
        color: white;
      }
      .btn-download {
        width: fit-content;
        border-radius: 15px;
        border: none;
        background-color: #39a7ff;
        padding: 12px 14px;
        font-size: 17px;
        color: white;
        margin-top: 10px;
    }
    .container-btnjoin {
        margin-top: 5px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    .btn-download a:hover {
        color: white;
    }
  }