header {
  background-color: #ffffff;
  opacity: 80%;
  margin: 0 0px;
  width: 100%;
}

header .nav {
  display: flex !important;
}

.logo1 {
  position: absolute;
  margin-left: 20px;
  margin-top: 1px;
  width: 85px;
  height: 85px;
}

.logo2 {
  position: absolute;
  margin-left: 100px;
  margin-top: 11px;
  width: 133px;
  height: 68px;
}

.start {
    background-color: #39a7ff;
    padding: 30px 70px;
    color: #fff;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
  } 

.login a {
  color: #fff;
  text-decoration: none;
}  

.login a:hover {
  color: #39a7ff;
}

@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #39a7ff;
    position: relative;
  }
  .logo1 {
    display: none;
  }
  
  .logo2 {
    display: none;
  }

  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #1eb2a6;
  }
}